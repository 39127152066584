import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/header'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router';

const Verification = () => {
  const history = useHistory();

    const [email, setEmail] = useState("");   
  useEffect(() => {
    // Fetch seller data from localStorage on component mount
    const seller = JSON.parse(localStorage.getItem("seller"));
    if (seller && seller.Email) {
      setEmail(seller.Email);
    }
  }, []);

  const handleSeller = async () => {
    const formData = new FormData();
    formData.append("paypal_email", email);
    try {
      const response = await axios.post(
        "https://ecommerceapi4.devssh.xyz/UserApi/verifyPaypal",
      formData
      );

      if (response.data.status) {
        const seller = JSON.parse(localStorage.getItem('seller'));
        seller.is_verified = true;
        localStorage.setItem('seller', JSON.stringify(seller));
        Swal.fire({
            title: "success",
            text: response?.data.message,
            icon: "success"
        })
        history.push('/newDashboard')
      }else{
        Swal.fire({
            title: "Opps",
            text: response?.data.message,
            icon: "error"
        })
      }

    }catch (error) {
      Swal.fire({
        title: "Opps",
        text: error.message,
        icon: "error"
    })
    }
  };

    return (
        <>
        
      <Header />
        
        
      <div className="" style={{
          minHeight: '224px',
          paddingLeft: '280px',
          paddingRight: '30px',
          paddingTop: '94px',
          width: '100%',
          position: 'relative'
      }}> <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Paypal Verification</h1>
      {email ? (
        <div>
          <p>Email: {email}</p>
          <button
            onClick={handleSeller}
            style={{
              padding: "10px 20px",
              backgroundColor: "#d72329",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Verify
          </button>
        </div>
      ) : (
        <p>No email found. Please log in first.</p>
      )}
    </div></div>
        </>
    )
}

export default Verification